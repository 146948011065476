<template>
  <div>
    <div class="row mt-2">
      <div class="col-10 mx-auto">
        <div class="card shadow-sm">
          <div class="card-body">
            <table class="table table-striped table-hover">
              <tbody>
                <tr>
                  <th class="d-flex justify-content-center"></th>
                  <th>Partner Name</th>
                </tr>
                <tr v-for="partner in partners" :key="partner.id">
                  <td class="d-flex justify-content-center">
                    <input
                      v-model="selectedPartner"
                      :value="partner.id"
                      type="radio"
                      class="form-check-input"
                    />
                  </td>
                  <td>{{ partner.name }}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center pt-3 mb-3">
              <b-button
                v-b-modal.partnerAssignModal
                :disabled="selectedPartner.length === 0"
                class="btn btn-primary"
                @click="modalInfo(selectedPartner)"
                v-if="!loading"
                >Assign partner</b-button
              >
              <b-button
                class="btn btn-warning ml-2"
                @click="$emit('toggle-is-hidden')"
                v-if="!loading"
                >Cancel</b-button
              >
              <p v-if="loading">Loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="partnerAssignModal"
      id="partnerAssignModal"
      hide-backdrop
      centered
      title="Assign partner"
    >
      <p class="my-4">
        Are you sure you want to assign these kiosks to this partner?
      </p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            size="md"
            class="float-right ml-2"
            @click="assignKiosks"
          >
            Assign
          </b-button>
          <b-button
            variant="warning"
            size="md"
            class="float-right"
            aria-label="Close"
            @click="hideModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnerListAssignKiosksTable",
  props: ["selectedKiosks"],
  data() {
    return {
      partner: "",
      partners: "",
      selectedPartner: "",
      responseErrors: {},
      loading: false,
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    async assignKiosks() {
      await this.updateKiosks(this.selectedKiosks, "Delivered");

      const assignPartnerKioskUrl = "v1/kiosks/assign-to-partner/";
      const data = {
        kiosks: this.selectedKiosks,
        partner: this.selectedPartner,
      };
      axios
        .post(assignPartnerKioskUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("get-kiosks", true);
            this.$emit("toggle-is-hidden");
            this.$emit("set-active-tab", "Active Kiosks", "assigned");
            this.$swal("Success", `${response.data.detail}`, "success");
          }
        })
        .catch((error) => {
          if (error.response === 406) {
            this.$swal(" ", `${error.response.data.detail}`, "info");
          } else if (error.response.data.detail) {
            this.$swal("Error", `${error.response.data.detail}`, "warning");
          }
        });
    },
    getPartners() {
      axios
        .get(`v1/partners/?is_active=True`)
        .then((response) => {
          if (response.status === 200) {
            this.partners = response.data.results.filter(
              (partner) =>
                partner.plan.name == "Kiosk" || partner.plan.name == "Premium"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    getPartner(id) {
      axios
        .get(`v1/partners/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.partner = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    modalInfo(partner) {
      this.selectedPartner = partner;
    },
    hideModal() {
      this.$refs["partnerAssignModal"].hide();
    },
    async updateKiosks(kiosks, location) {
      const updateKioskLocationUrl = "v1/kiosks/update-location-status/";
      await axios
        .post(updateKioskLocationUrl, { kiosks, location })
        .then(() => {})
        .catch((error) => {
          if (error.response.data.detail) {
            this.$swal("Error", `${error.response.data.detail}`, "warning");
          } else {
            this.$swal("Error", "Could not update kiosks", "warning");
          }
        });
    },
  },
};
</script>

<style></style>
