<template>
  <div class="col-12">
    <div class="card card-border-color card-border-color-secondary">
      <div class="card-header">
        <span class="text-uppercase">{{ activeTab }}</span>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item">
            <span
              :class="[
                'nav-link',
                activeTab === 'Kiosks to be Deployed' && 'active',
              ]"
              @click="
                () => handleSetActiveTab('Kiosks to be Deployed', 'transit')
              "
            >
              Kiosks to be Deployed
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'Active Kiosks' && 'active']"
              @click="() => handleSetActiveTab('Active Kiosks', 'assigned')"
            >
              Active Kiosks
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="[
                'nav-link',
                activeTab === 'Defective Kiosks' && 'active',
              ]"
              @click="() => handleSetActiveTab('Defective Kiosks', 'damaged')"
            >
              Defective Kiosks
            </span>
          </li>
          <li class="nav-item">
            <span
              @click="() => handleSetActiveTab('All Kiosks', 'all')"
              :class="['nav-link', activeTab === 'All Kiosks' && 'active']"
            >
              All Kiosks
            </span>
          </li>
        </ul>

        <div class="py-6" v-if="loading">
          <table-skeleton> </table-skeleton>
        </div>

        <DataTable
          v-if="!showPartnersSelect && !loading"
          :columns="[
            { field: 'serial_number', label: 'Serial Number' },
            { field: 'partner', label: 'Partner', formatFn: partnerFormatter },
            {
              field: 'functional',
              label: 'Status',
              formatFn: isFunctionalFormatter,
            },
            {
              field: 'location',
              label: 'Delivery Status',
            },
            {
              field: 'assigned_at',
              label: 'Date Assigned',
              formatFn: dateTimeFormatter,
            },
            { field: 'created', label: 'Added', formatFn: dateTimeFormatter },
            { field: 'view', label: 'Action', sortable: false },
          ]"
          :rows="filteredKiosks"
          :activeTab="activeTab"
          :totalRecords="totalKiosks"
          :basePaginatorUrl="basePaginatorUrl"
          :selectOptions="showSelect"
          detailsRouteName="kiosk-details"
          @toggle-is-hidden="toggleIsHidden"
          @get-selected-rows="getSelectedRows"
          @get-kiosks="getKiosks"
          @set-active-tab="handleSetActiveTab"
          @fetch-next-page-results="getKiosks"
        />
        <div v-if="showPartnersSelect" class="card-body">
          <PartnerListAssignKiosksTable
            @toggle-is-hidden="toggleIsHidden"
            @get-kiosks="getKiosks"
            @set-active-tab="handleSetActiveTab"
            :selectedKiosks="selectedKiosks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/DataTable";
import PartnerListAssignKiosksTable from "@/components/Partners/ListAssignKiosksTable";

export default {
  name: "KiosksTable",
  components: { DataTable, PartnerListAssignKiosksTable },
  data() {
    return {
      activeTab: "Kiosks to be Deployed",
      allKiosks: [],
      filteredKiosks: [],
      totalKiosks: null,
      basePaginatorUrl: null,
      filtered: false,
      showPartnersSelect: false,
      error: "",
      selectedKiosks: null,
      loading: true,
    };
  },
  computed: {
    showSelect() {
      if (
        this.activeTab === "All Kiosks" ||
        this.activeTab === "Kiosks to be Deployed"
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getKiosks(true, "v1/kiosks/?location=In+Transit");
  },
  methods: {
    getSelectedRows(records) {
      this.selectedKiosks = records;
    },
    getKiosks(clearKiosks, kioskUrl = "v1/kiosks/") {
      this.basePaginatorUrl = kioskUrl;
      if (clearKiosks) {
        this.filteredKiosks = [];
      }
      axios
        .get(kioskUrl)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.totalKiosks = response.data.count;
            this.allKiosks = response.data.results;
            this.filteredKiosks = this.allKiosks;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSetActiveTab(tabName, tabValue) {
      this.activeTab = tabName;

      if (tabValue === "all") {
        // ALL KIOSKS
        this.getKiosks(true);
      } else if (tabValue === "damaged") {
        // DEFECTIVE KIOSKS
        this.getKiosks(true, "v1/kiosks/?functional=false");
      } else if (tabValue === "assigned") {
        // ACTIVE KIOSKS
        this.getKiosks(true, "v1/kiosks/?location=Delivered&is_assigned=true");
      } else if (tabValue === "transit") {
        // KIOSKS TO BE DEPLOYED
        this.getKiosks(true, "v1/kiosks/?location=In+Transit");
      }
    },
    isFunctionalFormatter(value) {
      return value ? "" : "";
    },
    toggleIsHidden() {
      this.showPartnersSelect = !this.showPartnersSelect;
    },
    partnerFormatter(value) {
      return value ? value.name : "-";
    },
    dateTimeFormatter(value) {
      return value ? new Date(value).toLocaleString() : "-";
    },
  },
};
</script>
