<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'kiosks' }">Kiosks</router-link-active>
          </li>
        </ol>
      </nav>
    </div>
    <KioskComponent />
  </div>
</template>
<script>
import KioskComponent from "@/components/Kiosks/KioskComponent";

export default {
  components: { KioskComponent },
};
</script>


