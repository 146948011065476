<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <!-- <div class="card-header card-header-divider">
            Add Kiosk
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div> -->
          <div class="card-body">
            <KioskCreationForm/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <KiosksTable/>
    </div>
  </div>
</template>
<script>
import KioskCreationForm from "@/components/Kiosks/CreationForm";
import KiosksTable from "@/components/Kiosks/Table";

export default {
  name: "KioskComponent",
  components: {KioskCreationForm, KiosksTable},
  data() {
    return {
      isHidden: false,
    };
  },
};
</script>
<style></style>
