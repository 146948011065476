var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-border-color card-border-color-secondary"},[_c('div',{staticClass:"card-header"},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.activeTab))])]),_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"nav nav-tabs nav-justified"},[_c('li',{staticClass:"nav-item"},[_c('span',{class:[
              'nav-link',
              _vm.activeTab === 'Kiosks to be Deployed' && 'active',
            ],on:{"click":() => _vm.handleSetActiveTab('Kiosks to be Deployed', 'transit')}},[_vm._v(" Kiosks to be Deployed ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'Active Kiosks' && 'active'],on:{"click":() => _vm.handleSetActiveTab('Active Kiosks', 'assigned')}},[_vm._v(" Active Kiosks ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:[
              'nav-link',
              _vm.activeTab === 'Defective Kiosks' && 'active',
            ],on:{"click":() => _vm.handleSetActiveTab('Defective Kiosks', 'damaged')}},[_vm._v(" Defective Kiosks ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'All Kiosks' && 'active'],on:{"click":() => _vm.handleSetActiveTab('All Kiosks', 'all')}},[_vm._v(" All Kiosks ")])])]),(_vm.loading)?_c('div',{staticClass:"py-6"},[_c('table-skeleton')],1):_vm._e(),(!_vm.showPartnersSelect && !_vm.loading)?_c('DataTable',{attrs:{"columns":[
          { field: 'serial_number', label: 'Serial Number' },
          { field: 'partner', label: 'Partner', formatFn: _vm.partnerFormatter },
          {
            field: 'functional',
            label: 'Status',
            formatFn: _vm.isFunctionalFormatter,
          },
          {
            field: 'location',
            label: 'Delivery Status',
          },
          {
            field: 'assigned_at',
            label: 'Date Assigned',
            formatFn: _vm.dateTimeFormatter,
          },
          { field: 'created', label: 'Added', formatFn: _vm.dateTimeFormatter },
          { field: 'view', label: 'Action', sortable: false },
        ],"rows":_vm.filteredKiosks,"activeTab":_vm.activeTab,"totalRecords":_vm.totalKiosks,"basePaginatorUrl":_vm.basePaginatorUrl,"selectOptions":_vm.showSelect,"detailsRouteName":"kiosk-details"},on:{"toggle-is-hidden":_vm.toggleIsHidden,"get-selected-rows":_vm.getSelectedRows,"get-kiosks":_vm.getKiosks,"set-active-tab":_vm.handleSetActiveTab,"fetch-next-page-results":_vm.getKiosks}}):_vm._e(),(_vm.showPartnersSelect)?_c('div',{staticClass:"card-body"},[_c('PartnerListAssignKiosksTable',{attrs:{"selectedKiosks":_vm.selectedKiosks},on:{"toggle-is-hidden":_vm.toggleIsHidden,"get-kiosks":_vm.getKiosks,"set-active-tab":_vm.handleSetActiveTab}})],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }