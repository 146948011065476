<template>
  <form method="post" @submit.prevent="addKiosk">
    <div class="form-group pt-2">
      <label for="inputEmail">Serial Number</label>
      <input
        v-model="kiosk.serial_no"
        class="form-control"
        id="inputSerialNo"
        type="text"
        placeholder="Enter Kiosk Serial Number"
        required
      />
      <!-- <small v-if="responseErrors.kiosk.serial_number" class="text-danger">
        {{ responseErrors.kiosk.serial_number[0] }}
      </small> -->
    </div>
    <div class="form-group pt-2">
      <label for="inputPurchaseDate">Purchase Date</label>
      <b-form-datepicker
        id="inputPurchaseDate"
        v-model="kiosk.purchase_date"
      ></b-form-datepicker>

      <!-- <small v-if="responseErrors.kiosk.purchase_date" class="text-danger">
        {{ responseErrors.purchase_date[0] }}
      </small> -->
    </div>
    <div class="form-group pt-2">
      <label for="inputStatus">Status:</label>
      <select
        type="text"
        class="form-control form-control-sm"
        v-model="kiosk.status"
        required
      >
        <option value="">Select status</option>
        <option value="active">Functional</option>
        <option value="in-active">Not functional</option>
      </select>
    </div>
    <div class="d-flex justify-content-center pt-3">
      <button class="btn btn-primary" :disabled="submitting" type="submit">
        <span v-if="submitting" class="spinner-border spinner-border-sm"></span>
        {{submit}}</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "KioskCreationForm",
  data() {
    return {
      kiosk: {
        serial_no: "",
        purchase_date: "",
        status: "",
      },
      partners: "",
      responseErrors: {},
      submit: "Add Kiosk",
      submitting: false,
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    addKiosk() {
      this.submitting = true;
      this.submit = "Adding Kiosk..."

      const data = new FormData();
      data.append("serial_number", this.kiosk.serial_no),
        data.append(
          "is_functional",
          JSON.stringify(this.kiosk.status === "active")
        ),
        axios
          .post(
            `kiosks/?access_token=${localStorage.getItem("access_token")}`,
            data,
            { headers: { "content-type": "multipart/form-data" } }
          )
          .then((response) => {
            if (response.status === 201) {
              router.push({
                name: "kiosk-details",
                params: { id: response.data["kiosk"].id },
              });
              this.$swal("Sucess", "kiosk Added Successfully", "success");
            }
          })
          .catch((error) => {
            if (error.response) {
              this.responseErrors = error.response.data;
              this.loading = false;
            }
          });
    },
    // getPartners() {
    //   axios
    //     .get(
    //       `v1/subscriptions/partners/?access_token=${localStorage.getItem(
    //         "access_token"
    //       )}`
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.partners = response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         this.$swal("Error", "Could not fetch partners!", "error");
    //       }
    //     });
    // },
  },
};
</script>

<style></style>
